.MuiMenu-list {
  background: var(--secondary_color);
  min-width: 12rem;
  color: var(--color);
  .MuiMenuItem-root {
    padding-top: 10px;
    padding-bottom: 10px;
    color: var(--color);
  }
}
