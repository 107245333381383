#HomeMenu {
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--color);
  position: sticky;
  top: 4rem;
  .home-menu-item {
    width: fit-content;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    border-radius: 8px;
    transition: all 0.3s;
    &:hover {
      background: var(--highlight-background);
      transition: all 0.3s;
    }
    &.active {
      background: var(--highlight-background);
      color: var(--highlight-color);
      font-weight: 700;
      transition: all 0.3s;
    }
  }
}
