.MuiDialog-container {
  background: transparent;
  backdrop-filter: blur(2px);
  color: var(--color);

  .MuiDialog-paper {
    background-image: var(--background-image);
    border-radius: 5px;
    color: var(--color);
    & * {
      color: var(--color);
    }
  }
}
