#ThemeToggleContainer {
  width: 70px;
  height: 30px;
  border-radius: 20px;
  padding-top: 1px;
  padding-bottom: 1px;
  overflow: hidden;
  user-select: none;
  border: 1px solid transparent;
  transition: all 0.3s linear;
  * {
    transition: all 0.3s linear;
  }

  &:hover {
    border: 1px solid blue;
  }

  &.dark {
    background-color: black;
    .moon-child {
      transform: translateY(-2px);
    }
    .sun-child {
      transform: translateY(100px);
    }
  }

  &.light {
    background-color: rgb(223, 223, 223);
    .moon-child {
      transform: translateY(-100px);
    }
    .sun-child {
      transform: translateY(-32px);
    }
  }
}
