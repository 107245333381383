// @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import "muiOverrides/muiOverrides.scss";

:root,
html[data-theme="dark"] {
  --primary_color: rgb(15, 23, 91);
  --secondary_color: rgb(48, 61, 179);
  --background-color: rgba(0, 0, 0, 0.8);
  --highlight-background: rgba(225, 225, 225, 0.3);
  --highlight-color: rgb(53, 1, 121);
  --background-image: linear-gradient(
    var(--primary_color),
    var(--secondary_color)
  );
  --color: rgb(235, 235, 235);
  --color-lighter: rgb(220, 220, 220);
}

html[data-theme="light"] {
  --primary_color: rgb(255, 255, 255);
  --secondary_color: rgb(255, 255, 255);
  --background-color: rgba(0, 0, 0, 0.2);
  --highlight-background: rgba(225, 225, 225, 0.5);
  --highlight-color: rgb(140, 53, 253);
  --background-image: linear-gradient(
    var(--primary_color),
    var(--secondary_color)
  );
  --color: rgb(20, 20, 20);
  --color-lighter: rgb(70, 70, 70);
}

html {
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  background-color: var(--background-color);
  background-image: var(--background-image);
  background-origin: padding-box;
  background-position-x: 50%;
  background-position-y: 0%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  font-family: "Poppins", sans-serif;
  // transition: all 0.3s linear;
}

body {
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  background-color: transparent;
  color: var(--color);
  font-family: "Roboto", sans-serif;
  overflow: auto;
}

@media (max-width: 978px) {
  .container {
    padding: 0;
    margin: 0;
  }
}
