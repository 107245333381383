.post {
    background: transparent;
    transition: all 0.3s;
    border-radius: 5px;
    &:hover {
      background: var(--highlight-background);
    }
  
    .post-username {
      color: var(--color-lighter);
    }
  }
  